.container {
    width: 100%;
    height: 48px;
    position: sticky;
    z-index: 9;
    left: 0;
    bottom: 0;
    background-color: #fff;
    border-top: 1px solid rgb(218, 220, 224);
    display: flex;
    .tabItem {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        img {
            width: 24px;
            height: 24px;
        }
        div {
            font-size: 12px;
            font-weight: 500;
            color: #5f6368;
        }
    }
}

@media only screen and (min-width: 660px) {
    .container {
        display: none;
    }
}
