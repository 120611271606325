body {
    font-family: inherit;
    font-size: 14px;
    font-weight: normal;
}

* {
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box;
}

@import url("assets/font.css");
