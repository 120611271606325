@font-face {
    font-family: Google Sans;
    src: url("./fonts/GoogleSans-Regular.ttf");
}

@font-face {
    font-family: Roboto;
    src: url("./fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: Arial;
    src: url("./fonts/Arial.ttf");
}

@font-face {
    font-family: sans-serif;
    src: url("./fonts/Microsoft-Sans-Serif.ttf");
}
