.container {
    width: 100%;
    background-color: #ffffff;
    padding-top: 40px;
    padding-bottom: 40px;

    .infoTitle {
        font-size: 18px;
        color: #202124;
        display: flex;
        font-weight: 500;
        img {
            width: 20px;
            height: 20px;
            margin-top: 2px;
            margin-left: 16px;
        }
    }

    .content {
        width: calc(100% - 48px);
        max-width: 1296px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        .contentLeft {
            flex: 1;
            overflow: hidden;
            color: #5f6368;

            .swiperContainer {
                width: 100%;
                height: 320px;

                position: relative;
                .swiperContent {
                    width: 100%;
                    height: 100%;

                    .swiperImg {
                        min-width: 92px;
                        height: 100%;
                        object-fit: contain;
                        border-radius: 8px;
                        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
                        cursor: pointer;
                    }
                }
                .navigationNext {
                    width: 56px;
                    height: 56px;
                    position: absolute;
                    left: -28px;
                    top: 50%;
                    z-index: 2;
                    transform: translateY(-50%);
                    background-color: blue;
                }
            }
            .contentGameInfo {
                padding-top: 32px;
                .contentGameInfoText {
                    margin-top: 20px;
                }
            }
            .contentUpdateTime {
                margin-top: 24px;
                .contentUpdateTimeTitle {
                    font-weight: 500;
                    color: #202124;
                }
                .contentUpdateTimeContent {
                    margin-top: 4px;
                }
            }
            .contentTags {
                margin-top: 28px;
                height: 32px;
                display: flex;
                align-items: center;
                .contentTagItem {
                    margin-right: 12px;
                    height: 100%;
                    padding: 0 15px;
                    border-radius: 16px;
                    border: 1px solid rgb(218, 220, 224);
                    display: flex;
                    align-items: center;
                    color: rgb(95, 99, 104);
                    font-weight: 500;
                }
            }
            .contentSafe {
                margin-top: 24px;
                .contentSafeText {
                    margin-top: 20px;
                }
                .contentSafeTable {
                    width: 100%;
                    margin-top: 30px;
                    padding: 20px;
                    border: 1px solid rgb(218, 220, 224);
                    border-radius: 8px;
                    .contentSafeTableItem {
                        display: flex;
                        color: #5f6368;
                        margin-bottom: 20px;
                        img {
                            width: 20px;
                            height: 20px;
                            margin-right: 20px;
                        }
                    }
                    .contentSafeTableDetail {
                        color: #01875f;
                        font-weight: 500;
                        cursor: pointer;
                    }
                }
            }
            .contentRate {
                margin-top: 24px;
                color: #5f6368;
                .contentRateTitle {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    cursor: pointer;
                    .contentRateTitleR {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        color: #5f6368;
                        img {
                            width: 16px;
                            height: 16px;
                            margin-left: 8px;
                        }
                    }
                }
                .contentRateDevice {
                    height: 32px;
                    margin-top: 24px;
                    display: flex;
                    align-items: center;
                    .contentRateDeviceItem {
                        height: 100%;
                        margin-right: 12px;
                        background-color: #e1f1ed;
                        padding: 0 16px;
                        border-radius: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #056449;
                        font-weight: 500;
                        cursor: pointer;
                        img {
                            width: 16px;
                            height: 16px;
                            margin-right: 8px;
                        }
                    }
                    .contentRateDeviceItemPc {
                        height: 100%;
                        margin-right: 12px;
                        background-color: transparent;
                        padding: 0 16px;
                        border-radius: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #5f6368;
                        font-weight: 500;
                        border: 1px solid #d5d7d8;
                        cursor: pointer;
                        img {
                            width: 16px;
                            height: 16px;
                            margin-right: 8px;
                        }
                    }
                }
                .contentRateDetail {
                    margin-top: 24px;
                    width: 100%;
                    display: flex;
                    .contentRateDetailL {
                        flex-shrink: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .contentRateDetailLNum {
                            font-size: 56px;
                            color: #202124;
                        }
                        .contentRateDetailLStar {
                            height: 20px;
                            display: flex;
                            img {
                                width: 16px;
                                height: 16px;
                            }
                        }
                        .contentRateDetailEvaluate {
                            margin-top: 8px;
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                    .contentRateDetailR {
                        flex: 1;
                        padding-left: 24px;
                        .contentRateDetailRItem {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            margin-bottom: 2px;
                            .contentRateDetailRItemNum {
                                font-size: 12px;
                                margin-right: 12px;
                                flex-shrink: 0;
                            }
                            .contentRateDetailRItemProgress {
                                flex: 1;
                                height: 10px;
                                background-color: #e8eaed;
                                border-radius: 5px;

                                .contentRateDetailRItemProgressInner {
                                    width: 90%;
                                    height: 100%;
                                    background-color: #01875f;
                                    border-radius: 5px;
                                }
                            }
                        }
                    }
                }
                .contentRateEvaluate {
                    margin-top: 40px;
                    .contentRateEvaluateItem {
                        margin-bottom: 40px;
                        .contentRateEvaluateItemTitle {
                            height: 32px;
                            display: flex;
                            align-items: center;
                            .contentRateEvaluateItemTitleAvatar {
                                width: 32px;
                                height: 32px;
                                border-radius: 50%;
                                flex-shrink: 0;
                                cursor: pointer;
                            }
                            .contentRateEvaluateItemTitleName {
                                flex: 1;
                                padding-left: 16px;
                                padding-right: 16px;
                                color: #202124;
                            }
                            .contentRateEvaluateItemTitleMore {
                                width: 24px;
                                height: 24px;
                                cursor: pointer;
                            }
                        }
                        .contentRateEvaluateItemRate {
                            height: 20px;
                            margin-top: 16px;
                            display: flex;
                            align-items: center;
                            img {
                                width: 12px;
                                height: 12px;
                            }
                            div {
                                font-size: 12px;
                                padding-left: 8px;
                            }
                        }
                        .contentRateEvaluateItemContent {
                            margin-top: 8px;
                        }
                        .contentRateEvaluateItemUserful {
                            margin-top: 16px;
                            font-size: 12px;
                        }
                        .contentRateEvaluateItemIsUserful {
                            height: 24px;
                            margin-top: 12px;
                            display: flex;
                            align-items: center;
                            .contentRateEvaluateItemIsUserfulText {
                                font-size: 12px;
                                margin-right: 24px;
                            }
                            .contentRateEvaluateItemIsUserfulBtn {
                                height: 24px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding-left: 12px;
                                padding-right: 12px;
                                font-size: 14px;
                                border: 1px solid #d5d7d8;
                                border-radius: 12px;
                                margin-right: 12px;
                                cursor: pointer;
                            }
                        }
                    }
                    .contentRateEvaluateMore {
                        color: #01875f;
                        font-weight: 500;
                        cursor: pointer;
                    }
                }
            }
            .contentNew {
                margin-top: 40px;
                cursor: pointer;
                .contentNewContent {
                    margin-top: 16px;
                    color: #5f6368;
                }
            }
        }
        .contentRight {
            margin-top: 32px;
            flex-shrink: 0;
            width: 100%;
            .contentRightOtherGameList {
                padding-top: 24px;
                display: grid;
                grid-template-columns: auto auto;
                gap: 24px;
            }
            .contentRightGameItem {
                width: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;

                .contentRightGameItemImg {
                    width: 56px;
                    height: 56px;
                    flex-shrink: 0;
                    border-radius: 12px;
                    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
                }
                .contentRightGameItemImfo {
                    flex: 1;
                    padding-left: 16px;
                    .contentRightGameItemImfoTitle {
                        color: #202124;
                    }
                    .contentRightGameItemImfoDesc {
                        font-size: 12px;
                    }
                    .contentRightGameItemImfoStar {
                        display: flex;
                        align-items: center;
                        color: #5f6368;
                        font-size: 12px;
                        img {
                            margin-left: 4px;
                            width: 10px;
                            height: 10px;
                        }
                    }
                }
            }
            .contentRightOther {
                margin-top: 32px;
            }
        }
    }
    .report {
        width: calc(100% - 48px);
        max-width: 1296px;
        height: 20px;
        margin: 24px auto 0;
        display: flex;
        align-items: center;
        color: #202124;
        font-weight: 500;
        cursor: pointer;

        img {
            width: 18px;
            height: 18px;
            margin-right: 16px;
        }
    }
    .footer {
        width: calc(100% - 48px);
        max-width: 1296px;
        margin: 36px auto;
        .divider {
            width: 100%;
            height: 1px;
            background-color: #e8eaed;
        }
        .footerInfo {
            margin-top: 36px;
            display: flex;
            color: #5f6368;

            .footerInfoItem {
                width: 244px;
                margin-right: 16px;
                .footerInfoItemTitle {
                    font-weight: 500;
                    cursor: pointer;
                }
                .footerInfoItemText {
                    margin-top: 12px;
                    cursor: pointer;
                }
            }
        }
    }
    .desc {
        width: calc(100% - 48px);
        max-width: 1296px;
        height: 48px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        color: #5f6368;
        font-size: 12px;
        flex-wrap: wrap;
        div {
            margin-top: 32px;
            margin-right: 24px;
            cursor: pointer;
        }
        img {
            margin-top: 32px;
            width: 24px;
            height: 18px;
            margin-right: 12px;
            cursor: pointer;
        }
    }
}

@media only screen and (min-width: 660px) {
    .container {
        .content {
            width: calc(100% - 96px);
            .contentRight {
                .contentRightOtherGameList {
                    grid-template-columns: auto auto;
                }
            }
        }
        .report {
            width: calc(100% - 96px);
        }
        .footer {
            width: calc(100% - 96px);
        }
        .desc {
            width: calc(100% - 96px);
        }
    }
}

@media only screen and (min-width: 960px) {
    .container {
        .content {
            flex-direction: row;
            .contentRight {
                width: 276px;
                margin-left: 80px;
                .contentRightOtherGameList {
                    grid-template-columns: auto;
                }
            }
        }
    }
}
