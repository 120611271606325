.container {
    width: 100%;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 9;
    background-color: #fff;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    .containerTop {
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 28px;
        padding-right: 28px;
        > div {
            height: 100%;
            display: flex;
            align-items: center;
        }
        .containerTopL {
            .googleIcon {
                width: 40px;
                height: 40px;
                cursor: pointer;
            }
            .containerTopLTitle {
                color: #5f6368;
                font-size: 22px;
                padding-left: 8px;
                font-family: "Google Sans", Roboto, Arial, sans-serif;
                cursor: pointer;
            }
            .containerTopLMenus {
                height: 100%;
                display: none;
                align-items: center;
                padding-left: 14px;
                .containerTopLMenuItem {
                    width: 60px;
                    height: 100%;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: rgb(95, 99, 104);
                    cursor: pointer;
                    .itemBottom {
                        width: 20px;
                        height: 3px;
                        border-radius: 3px;
                        background-color: #01875f;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
        .containerTopR {
            .containerTopRItem {
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                cursor: pointer;
                &:hover {
                    background-color: #f5f5f5;
                }
                .itemIcon {
                    width: 20px;
                    height: 20px;
                }
                .googleIcon {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
    .containerTopMenus {
        width: 100%;
        height: 48px;
        display: none;
        align-items: center;
        justify-content: center;
        .containerTopMenuItem {
            flex: 1;
            max-width: 112px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgb(95, 99, 104);
            cursor: pointer;
        }
    }
}

// PC
@media only screen and (min-width: 660px) {
    .container {
        .containerTop {
            .containerTopL {
                .containerTopLMenus {
                    display: none;
                }
            }
        }
        .containerTopMenus {
            display: flex;
        }
    }
}

@media only screen and (min-width: 840px) {
    .container {
        .containerTop {
            .containerTopL {
                .containerTopLMenus {
                    display: flex;
                }
            }
        }
        .containerTopMenus {
            display: none;
        }
    }
}
