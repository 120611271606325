.container {
    background-color: #202124;
    padding-bottom: 32px;
    position: relative;

    .contentVideo {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        z-index: 0;
        overflow: hidden;
        .contentVideoContent {
            width: 100%;
            position: relative;
            #contentVideo {
                width: 100%;
                z-index: 0;
            }
            .contentVideoMask {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: linear-gradient(to right, rgb(32, 33, 36) 0, rgba(0, 0, 0, 0) 56%), linear-gradient(to top, rgb(32, 33, 36) 0, rgba(0, 0, 0, 0) 56%);
            }
        }
    }
    .content {
        width: calc(100% - 96px);
        max-width: 1296px;
        margin: 0 auto;
        position: relative;
        .contentEmpty {
            width: 100%;
            height: calc(56.25vw - 40px);
        }

        .contentInfo {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            min-height: 56.25vw;
            z-index: 2;
            .infoTitle {
                display: flex;
                .infoTitleImg {
                    width: 72px;
                    height: 72px;
                    border-radius: 20%;
                    margin: 7px 24px 0 0;
                }
                .infoTitleContent {
                    .infoName {
                        color: #e8eaed;
                        font-size: 24px;
                    }
                    .infoDesc {
                        margin-top: 2px;
                        font-size: 16px;
                        color: #00a173;
                    }
                    .infoDesc2 {
                        color: #9aa0a6;
                        font-size: 12px;
                    }
                }
            }
            .infoDownLoad {
                height: 64px;
                margin: 16px 0 0 0;
                display: flex;
                align-items: center;
                .infoDownLoadItem {
                    min-width: 96px;
                    padding: 0 16px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    &:nth-child(1) {
                        padding-left: 0;
                    }
                    .infoDownLoadItemName {
                        height: 24px;
                        font-size: 14px;
                        color: #e8eaed;
                        display: flex;
                        align-items: center;
                        img {
                            width: 12px;
                            height: 12px;
                            margin-left: 4px;
                        }
                        .infoDownLoadItemName12 {
                            width: 22px;
                            height: 16px;
                        }
                    }
                    .infoDownLoadItemDesc {
                        font-size: 12px;
                        color: #9aa0a6;
                        display: flex;
                        align-items: center;
                        img {
                            width: 12px;
                            height: 12px;
                            margin-left: 4px;
                        }
                    }
                }
                .infoDownLoadItemgameImgImg {
                    display: none;
                }
                .infoDownLoadDivider {
                    width: 1px;
                    height: 24px;
                    background-color: rgb(60, 64, 67);
                    flex-shrink: 0;
                }
            }
            .infoHandler {
                margin-top: 24px;
                display: flex;
                flex-direction: column;
                .infoHandlerDownload {
                    width: 100%;
                    height: 36px;
                    background-color: #00a173;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #202124;
                    font-size: 14px;
                    cursor: pointer;
                }
                .infoHandlerShare {
                    margin-top: 6px;
                    width: 100%;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #00a173;
                    .infoHandlerShareItem {
                        padding-left: 8px;
                        padding-right: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        img {
                            width: 24px;
                            height: 24px;
                            margin-right: 8px;
                        }
                    }
                }
            }
            .infoDevice {
                margin-top: 24px;
                display: flex;
                align-items: center;
                color: #9aa0a6;
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 12px;
                }
            }
        }
    }
}

// PC
@media only screen and (min-width: 600px) {
    .container {
        .content {
            .contentEmpty {
                width: 100%;
                height: 112px;
            }
            .contentInfo {
                .infoTitle {
                    .infoTitleImg {
                        width: 96px;
                        height: 96px;
                    }
                    .infoTitleContent {
                        .infoName {
                            font-size: 44px;
                        }
                    }
                }
                .infoHandler {
                    flex-direction: row;
                    align-items: center;
                    .infoHandlerDownload {
                        width: auto;
                        padding-left: 70px;
                        padding-right: 70px;
                    }
                    .infoHandlerShare {
                        margin-top: 0;
                        width: auto;
                        .infoHandlerShareItem {
                            padding-left: 32px;
                            padding-right: 0;
                            .infoHandlerShareItemName {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 960px) {
    .container {
        .content {
            .contentInfo {
                .infoTitle {
                    .infoTitleContent {
                        .infoDesc {
                            margin-top: 16px;
                        }
                    }
                    .infoTitleImg {
                        display: none;
                    }
                }
                .infoDownLoad {
                    .infoDownLoadItemgameImgImg {
                        display: block;
                        width: 48px;
                        height: 48px;
                        margin-right: 16px;
                        border-radius: 20%;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .container {
        .contentVideo {
            width: 832px;
            height: 468px;
            #contentVideo {
                width: 100%;
                z-index: 0;
            }
        }
        .content {
            .contentEmpty {
                width: 100%;
                height: 112px;
            }
            .contentInfo {
                min-height: 436px;
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .container {
        .contentVideo {
            width: 947px;
            height: 532px;
        }
        .content {
            .contentEmpty {
                width: 100%;
                height: 112px;
            }
            .contentInfo {
                min-height: 500px;
                .infoTitle {
                    .infoTitleContent {
                        .infoName {
                            font-size: 64px;
                        }
                    }
                }
                .infoHandler {
                    .infoHandlerShare {
                        .infoHandlerShareItem {
                            .infoHandlerShareItemName {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1296px) {
    .container {
        .contentVideo {
            right: calc((100vw - 1296px) / 2);
            .contentVideoContent {
                .contentVideoMask {
                    background: linear-gradient(to right, rgb(32, 33, 36) 0, rgba(0, 0, 0, 0) 56%),
                        linear-gradient(to top, rgb(32, 33, 36) 0, rgba(0, 0, 0, 0) 56%), linear-gradient(to left, rgb(32, 33, 36) 0, rgba(0, 0, 0, 0) 56%);
                }
            }
        }
    }
}

@media only screen and (min-width: 1440px) {
    .container {
        .contentVideo {
            width: 1067px;
            height: 600px;
        }
        .content {
            .contentEmpty {
                width: 100%;
                height: 112px;
            }
            .contentInfo {
                min-height: 568px;
            }
        }
    }
}
